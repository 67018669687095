import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Router } from "react-router";
import reportWebVitals from "./reportWebVitals";
import EditorJsParser from "editorjs-parser";
import { onError } from "@apollo/client/link/error";
import { CookiesProvider } from "react-cookie";
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  concat,
  from,
  ApolloProvider,
} from "@apollo/client";
import history from "./history";
import { CONSTANTS } from "./constants";
import "./index.scss";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const httpLink = new HttpLink({ uri: CONSTANTS.NEST_BACKEND_URL });

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("token");
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null,
    },
  });
  return forward(operation);
});

const errorLink1 = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      if (message === "Unauthorized") {
        console.log("Unauthorized Unauthorized Unauthorized 2");
        const redirectLink = window.location.href;
        
        if(window.location.href.indexOf("quickflow") !== -1) {
          localStorage.setItem("redirectLink", redirectLink);
        }
        if(window.location.href.indexOf("custom-refdoc") !== -1) {
          localStorage.setItem("redirectLink", redirectLink);
        }

        if (window.location.href.indexOf("login") === -1) {
          window.location.href = "/ih/login";
        }
        // history.push("/login");
        // every 401/unauthorized error will be caught here and update the global local state
        // localStorage.removeItem('authToken');
      }
    });
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authMiddleware, errorLink, httpLink]),
  resolvers: {
    SectionEntity: {
      content_html: (question, _args, { cache }) => {
        const markup = parser.parse(question.content || { blocks: [] });
        return markup;
      },
    },
    UserEntity: {
      initials: (user, _args, { cache }) => {
        let initials = user.first_name[0];
        if (user.last_name && user.last_name.length) {
          initials = initials + user.last_name[0];
        }
        return initials;
      },
    },
    QuestionEntity: {
      uuid: (question, _args, { cache }) => {
        return parseInt(Math.random() * 10000000000 + "");
      },
      content_html: (question, _args, { cache }) => {
        const markup = parser.parse(question.content);
        return markup;
      },
      static_content_html: (question, _args, { cache }) => {
        const markup = parser.parse(question.static_content || { blocks: [] });
        return markup;
      },
    },
    AnswerEntity: {
      content_html: (answer, _args, { cache }) => {
        const markup = parser.parse(answer.content);
        return markup;
      },
    },
  },
});

const parser = new EditorJsParser(
  {},
  {
    video: (data: any, config: any) => {
      const url = data.url ?? data.file.url;
      if(data.autoplay) {
        return `
          <div class="mstr-video-autoplay">
            <video autoplay muted loop src="${url}" />
          </div>
        `;
      }

      return `
        <div class="mstr-video">
          <video controls src="${url}" />
        </div>
      `;
    },
    paragraph: (data: any, config: any) => {
      return `<div>${data.text}</div>`;
    },
    link: (data: any, config: any) => {
      return `<a href="${data.link}">${data.meta.title}</a>`;
    },
    image: (data: any, config: any) => {
      return `
        <div class="mstr-image">
          <img src="${data.url || data.file.url}" />
        </div>
      `;
    },
    delimiter: (data: any, config: any) => {
      return `
        <div class="mstr-delimiter">
          <br />
        </div>
      `;
    },
  }
);

ReactDOM.render(
  // <React.StrictMode>
  <ApolloProvider client={client}>
    <CookiesProvider>
      <BrowserRouter basename="/ih">
        <App />
      </BrowserRouter>
    </CookiesProvider>
  </ApolloProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

console.log('Version 1.0.2');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
