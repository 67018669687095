import { CheckIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import React from "react";

interface Props {
  currentStep: number;
  totalSteps: number;
}

export const StepIndicator = (props: Props) => {
  const { currentStep, totalSteps } = props;
  return (
    <nav aria-label="Progress" className="px-4">
      <div role="list" className="flex items-center justify-center">
        {[...Array(props.totalSteps)].map((step, stepIdx) => (
          <div
            key={stepIdx}
            className={classNames(
              stepIdx !== props.totalSteps - 1 ? "pr-2 sm:pr-6" : "",
              "relative"
            )}
          >
            {stepIdx < currentStep ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-blue-600" />
                </div>
                <a
                  className="relative w-5 h-5 flex items-center justify-center bg-blue-600 rounded-full hover:bg-blue-900"
                >
                  <CheckIcon
                    className="w-3 h-3 text-white"
                    aria-hidden="true"
                  />
                </a>
              </>
            ) : stepIdx === currentStep ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <a
                  className="relative w-5 h-5 flex items-center justify-center bg-white border-2 border-blue-600 rounded-full"
                  aria-current="step"
                >
                  <span
                    className="h-2 w-2 bg-blue-600 rounded-full"
                    aria-hidden="true"
                  />
                </a>
              </>
            ) : (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <a
                  className="group relative w-5 h-5 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full hover:border-gray-400"
                >
                  <span
                    className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                </a>
              </>
            )}
          </div>
        ))}
      </div>
    </nav>
  );
};
